#TelaHome {
    background-color: var(--branco);
    background-image: url("../../assets/brand/background2.png");
    background-position: bottom;
    background-repeat: repeat-x;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.4em;
}

#TelaHome > a {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 40%;
    min-width: fit-content;
    width: 15em;
    height: 2.4em;
    font-size: 2em;
    border: 1px solid var(--preto);
    outline: 0px;
    background-color: var(--azulPetroleoDirecty);
    color: var(--branco);
    border-radius: 0.4em;
    transition: 0.2s ease;
    cursor: pointer;
    text-decoration: none;
}

#TelaHome > a:hover {
    background-color: var(--digitalColorDirecty);
    transform: translateY(-0.15em);
}

#loginPage {
    padding: 2em;
    background-color: var(--branco);
    border: 1px solid var(--preto);
    border-radius: 1em;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

#loginPage > h1 {
    padding: 0px;
    margin: 0px;
    color: var(--azulPetroleoDirecty);
}

#loginPage > form {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: flex-start;
    justify-content: center;
}

#loginPage > form > label {
    min-width: 30vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#loginPage > form > label > input {
    width: 100%;
    padding: 1em 1em;
    box-sizing: border-box;
    border: 1px solid var(--bordaInputLogin);
    border-radius: 0.4em;
    font-size: 0.9em;
}

#loginPage > form > button[type="submit"] {
    align-self: center;
    padding: 1em 2em;
    background-color: var(--azulPetroleoDirecty);
    border: 0px;
    outline: 0px;
    box-sizing: border-box;
    color: var(--branco);
    border-radius: 0.5em;
    font-weight: bold;
    font-size: 0.9em;
    cursor: pointer;
}

#loginPage > img {
    max-width: 100%;
    height: 6em;
    align-self: center;
}

#loginPage > form > .novaSenhaBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--transparent);
    border: 0px;
    outline: 0px;
    align-self: center;
    padding: 0em 0.2em;
    color: var(--azulPetroleoDirecty);
    border-bottom: 1px solid var(--azulPetroleoDirecty);
}

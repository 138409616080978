#baseHomePage {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
}

#baseHomePage > .baseHomePageOutlet {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: auto;
}

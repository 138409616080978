#TelaDesafios {
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4em;
    box-sizing: border-box;
    position: relative;
    background-image: url("../../assets/brand/background3.png");
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: cover;
    z-index: 0;
}

#TelaDesafios::after {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(
        to bottom,
        var(--branco),
        var(--branco) 30%,
        var(--transparent)
    );
    content: "";
    z-index: -1;
}

#TelaDesafios > #GridDesafios {
    max-width: 80%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2em;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio {
    border: 1px dashed var(--azulPetroleoDirecty);
    border-radius: 0.4em;
    padding: 1em;
    background-color: var(--menuBackground);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
    text-decoration: none;
    color: inherit;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > h3 {
    color: var(--azulPetroleoDirecty);
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > p {
    margin: 0;
    padding: 0;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .descricaoHolder > span:first-of-type,
#TelaDesafios > #GridDesafios > .CaixaDesafio > .dificuldadeHolder > span:first-of-type,
#TelaDesafios > #GridDesafios > .CaixaDesafio > .habilidadesHolder > span:first-of-type,
#TelaDesafios > #GridDesafios > .CaixaDesafio > .enviadoHolder > span:first-of-type,
#TelaDesafios > #GridDesafios > .CaixaDesafio > .prazoHolder > span:first-of-type {
    font-weight: bold;
    margin-right: 0.8em;
    color: var(--azulPetroleoDirecty);
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .dificuldadeHolder,
#TelaDesafios > #GridDesafios > .CaixaDesafio > .enviadoHolder {
    display: flex;
    align-items: center;
    flex-direction: row;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .dificuldadeHolder > .starsHolder {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .dificuldadeHolder > .starsHolder > .star {
    font-size: 1.6em;
    color: var(--cinzaDirecty);
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .dificuldadeHolder > .starsHolder > .star.fill {
    color: var(--insightsColorDirecty);
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .habilidadesHolder {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .habilidadesHolder > ul {
    margin: 0;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .enviadoHolder > .enviadoIcon {
    display: inline-flex;
    align-items: center;
    gap: 0.4em;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .enviadoHolder > .enviadoIcon > .check {
    color: var(--operationColorDirecty);
    font-size: 1.5em;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .enviadoHolder > .enviadoIcon > .uncheck {
    color: var(--consultingColorDirecty);
    font-size: 1.5em;
}

#TelaDesafios > #GridDesafios > .CaixaDesafio > .CaixaDesafioButton {
    margin-top: 1em;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: 1.2em;
    color: var(--azulPetroleoDirecty);
    text-align: center;
}

#rodapeComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1em;
    height: 2.4em;
    border-top: 1px solid var(--bordaCabecalho);
    z-index: 99999;
}

#rodapeComponent > span {
    color: var(--azulPetroleoDirecty);
    font-size: 0.8em;
}

#rodapeComponent > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.4em;
    height: 50%;
    box-sizing: border-box;
}

#rodapeComponent > div > a {
    height: 100%;
    text-decoration: none;
    color: var(--azulPetroleoDirecty);
}

#rodapeComponent > div > a > img {
    height: 100%;
}

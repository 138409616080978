html,
body,
.App {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.App {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    --transparent: #00000000;
    --branco: #ffffff;
    --preto: #000000;
    --bordaCabecalho: #dddddd;
    --azulPetroleoDirecty: #334c5d;
    --cinzaDirecty: #a8b2b0;
    --digitalColorDirecty: #7da9db;
    --operationColorDirecty: #3cb5a1;
    --insightsColorDirecty: #ecc962;
    --consultingColorDirecty: #ec5f65;
    --menuBackground: #f3f3f3;
    --bordaInputLogin: #777777;
}

#TelaDesafio {
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 4em;
    box-sizing: border-box;
    position: relative;
    background-image: url("../../assets/brand/background3.png");
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: cover;
    z-index: 0;
}

#TelaDesafio::after {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    background-image: linear-gradient(
        to bottom,
        var(--branco),
        var(--branco) 30%,
        var(--transparent)
    );
    content: "";
    z-index: -1;
}

#TelaDesafio > .CaixaDesafio {
    border: 1px dashed var(--azulPetroleoDirecty);
    border-radius: 0.4em;
    padding: 1em;
    background-color: var(--menuBackground);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
    text-decoration: none;
    color: inherit;
    max-width: 70%;
}

#TelaDesafio > .CaixaDesafio > h3 {
    color: var(--azulPetroleoDirecty);
}

#TelaDesafio > .CaixaDesafio > p {
    margin: 0;
    padding: 0;
}

#TelaDesafio > .CaixaDesafio > .descricaoHolder > span:first-of-type,
#TelaDesafio > .CaixaDesafio > .dificuldadeHolder > span:first-of-type,
#TelaDesafio > .CaixaDesafio > .habilidadesHolder > span:first-of-type,
#TelaDesafio > .CaixaDesafio > .enviadoHolder > span:first-of-type,
#TelaDesafio > .CaixaDesafio > .prazoHolder > span:first-of-type {
    font-weight: bold;
    margin-right: 0.8em;
    color: var(--azulPetroleoDirecty);
}

#TelaDesafio > .CaixaDesafio > .dificuldadeHolder,
#TelaDesafio > .CaixaDesafio > .enviadoHolder {
    display: flex;
    align-items: center;
    flex-direction: row;
}

#TelaDesafio > .CaixaDesafio > .dificuldadeHolder > .starsHolder {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

#TelaDesafio > .CaixaDesafio > .dificuldadeHolder > .starsHolder > .star {
    font-size: 1.6em;
    color: var(--cinzaDirecty);
}

#TelaDesafio > .CaixaDesafio > .dificuldadeHolder > .starsHolder > .star.fill {
    color: var(--insightsColorDirecty);
}

#TelaDesafio > .CaixaDesafio > .habilidadesHolder {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

#TelaDesafio > .CaixaDesafio > .habilidadesHolder > ul {
    margin: 0;
}

#TelaDesafio > .CaixaDesafio > .enviadoHolder > .enviadoIcon {
    display: inline-flex;
    align-items: center;
    gap: 0.4em;
}

#TelaDesafio > .CaixaDesafio > .enviadoHolder > .enviadoIcon > .check {
    color: var(--operationColorDirecty);
    font-size: 1.5em;
}

#TelaDesafio > .CaixaDesafio > .enviadoHolder > .enviadoIcon > .uncheck {
    color: var(--consultingColorDirecty);
    font-size: 1.5em;
}

#TelaDesafio > .CaixaDesafio > .divisoria {
    width: 90%;
    border-bottom: 1px solid var(--cinzaDirecty);
}

#TelaDesafio > .CaixaDesafio > form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
}

#TelaDesafio > .CaixaDesafio > form > label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 0.1em;
}

#TelaDesafio > .CaixaDesafio > form > label > input,
#TelaDesafio > .CaixaDesafio > form > label > textarea {
    width: 100%;
    box-sizing: border-box;
    background-color: var(--transparent);
    border: 1px solid var(--azulPetroleoDirecty);
    border-radius: 0.4em;
    padding: 0.4em 1em;
    font-size: 1em;
}

#TelaDesafio > .CaixaDesafio > form > button[type="submit"] {
    border: 0px;
    outline: 0px;
    cursor: pointer;
    background-color: var(--azulPetroleoDirecty);
    color: var(--branco);
    padding: 0.6em 4em;
    border-radius: 0.4em;
    font-size: 1em;
}

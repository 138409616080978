#menuComponent {
    border-radius: 1em;
    background-color: var(--menuBackground);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    border: 2px solid var(--bordaCabecalho);
    box-sizing: border-box;
    overflow: hidden;
}

#menuComponent > .menuIcon {
    background-color: var(--transparent);
    border: 0px;
    outline: 0px;
    cursor: pointer;
    padding: 0.6em;
    margin: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1em;
    gap: 0.4em;
    transition: 0.15s ease;
}

#menuComponent > .menuIcon.close {
    gap: 0;
}

#menuComponent > .menuIcon > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

#menuComponent > .menuIcon > .menuSpanIcon {
    font-size: 1.4em;
}

#menuComponent > .menuIcon > .menuSpanFechar {
    font-size: 1em;
    transition: 0.15s ease;
}

#menuComponent > .menuIcon.close > .menuSpanFechar {
    font-size: 0em;
}

#menuComponent > .menuItens {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 0.6em;
    gap: 0.8em;
    box-sizing: border-box;
    transition: 0.15s ease;
    border-top: 2px solid var(--bordaCabecalho);
}

#menuComponent > .menuItens.close {
    border-top-width: 0px;
    padding: 0em;
    gap: 0em;
}

#menuComponent > .menuItens > a,
#menuComponent > .menuItens > .sairButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7em;
    text-decoration: none;
    font-size: 1em;
    color: var(--azulPetroleoDirecty);
    transition: 0.15s ease;
    background-color: var(--transparent);
    border: 0px;
    outline: 0px;
    padding: 0px;
    font-family: inherit;
    cursor: pointer;
}

#menuComponent > .menuItens > a.active {
    color: var(--digitalColorDirecty);
    font-weight: bold;
}

#menuComponent > .menuItens.close > a,
#menuComponent > .menuItens.close > .sairButton {
    font-size: 0em;
}

#menuComponent
    > .menuItens
    > a
    > span:first-of-type
    #menuComponent
    > .menuItens
    > .sairButton
    > span:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    font-size: 1.4em;
}

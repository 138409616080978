#cabecalhoComponent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    z-index: 99999;
}

#cabecalhoComponent > .itensHolder {
    padding: 0px 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 5em;
    overflow: hidden;
    border-bottom: 1px solid var(--bordaCabecalho);
    box-sizing: border-box;
}

#cabecalhoComponent > .itensHolder > *:not(.linkLogo) {
    flex: 1;
}

#cabecalhoComponent > .itensHolder > .linkLogo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    padding: 0.2em 0px;
    transition: 0.2s ease;
}

#cabecalhoComponent > .itensHolder > .linkLogo:hover {
    transform: scale(1.1);
}

#cabecalhoComponent > .itensHolder > .linkLogo > img {
    height: 75%;
}

#cabecalhoComponent > .itensHolder > .perfilHolder {
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    box-sizing: border-box;
    overflow: hidden;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .linkPerfil {
    box-sizing: border-box;
    height: 80%;
    border-radius: 1000px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .linkPerfil > img {
    height: 100%;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .welcomeHolder {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .welcomeHolder > p {
    padding: 0;
    margin: 0;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .welcomeHolder > p:first-of-type {
    color: var(--azulPetroleoDirecty);
    font-size: 1em;
}

#cabecalhoComponent > .itensHolder > .perfilHolder > .welcomeHolder > p:last-of-type {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    gap: 0.2em;
}

#cabecalhoComponent
    > .itensHolder
    > .perfilHolder
    > .welcomeHolder
    > p:last-of-type
    > span:first-of-type {
    color: var(--azulPetroleoDirecty);
    font-weight: bold;
    font-size: 1.4em;
}

#cabecalhoComponent
    > .itensHolder
    > .perfilHolder
    > .welcomeHolder
    > p:last-of-type
    > span:last-of-type {
    color: var(--preto);
    text-transform: uppercase;
}

#cabecalhoComponent > .itensHolder > .languagesHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
    gap: 1em;
}

#cabecalhoComponent > .itensHolder > .languagesHolder > button {
    background-color: var(--transparent);
    padding: 0px;
    margin: 0px;
    outline: 0px;
    transition: 0.2s ease;
    height: 70%;
    cursor: pointer;
    overflow: hidden;
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-rows: 1fr auto;
    border: 0px;
    box-sizing: border-box;
    gap: 0.4em;
}

#cabecalhoComponent > .itensHolder > .languagesHolder > button > div {
    border: 1px solid var(--preto);
    height: 100%;
    border-radius: 15px;
    box-sizing: border-box;
    overflow: hidden;
    align-self: center;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

#cabecalhoComponent > .itensHolder > .languagesHolder > button > div > img {
    height: 100%;
}

#cabecalhoComponent > .itensHolder > .languagesHolder > button > span {
    color: var(--preto);
    font-size: 1.1em;
}

#cabecalhoComponent > .itensHolder > .languagesHolder > button > span.active {
    color: var(--azulPetroleoDirecty);
    font-weight: bold;
    text-decoration: underline;
}

#cabecalhoComponent > .menuHolder {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    padding: 0.6em;
}

@keyframes backgroundLinearMove {
    from {
        background-position: 100% 0%;
    }
    to {
        background-position: 0% 100%;
    }
}

#baseLoginPage {
    --c1Bg: #334C5D;
    --c2Bg: #A8B2B0;
}

#baseLoginPage {
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    background-size: 200% 200%;
    background-repeat: no-repeat;
    animation: backgroundLinearMove 16s linear infinite;
    background-image: repeating-linear-gradient(
        to top right,
        var(--c1Bg),
        var(--c2Bg),
        var(--c1Bg),
        var(--c2Bg),
        var(--c1Bg)
    );
}

#baseLoginPage > .baseLoginPageOutlet {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}